<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0">承認代理追加</h3>
              </b-card-header>
              <b-card-body>
                <b-form @submit.prevent>
                  <h6 class="heading-small text-muted mb-4">基本情報</h6>
                  <div class="pl-lg-4">
                    <b-row>
                      <b-col md="12" lg="12">
                        <base-select
                          label="ワークフロー"
                          v-model="approval_proxy.workflow" 
                          :options="workflow_choice"
                        >
                        </base-select>
                      </b-col>
                      <b-col md="12" lg="12">
                        <base-select
                          label="所属部署"
                          v-model="approval_proxy.org_id"
                          :options="getOrganizationchoices"
                          helpText="該当組織配下の社員のみ適用、選択しない場合は担当部署配下の全員に適用"
                        >
                        </base-select>
                      </b-col>
                      <b-col md="12" lg="12">
                        <label>承認者</label>
                        <multiselect 
                          v-model="approval_selected"
                          :options="approver_choice"
                          :multiple="false"
                          label="text"
                          track-by="value"
                        >
                        </multiselect>
                      </b-col>
                      <b-col md="12" lg="12">
                        <div class="mt-3">
                          <b-form-checkbox
                            v-model="approval_proxy.is_silence"
                          >
                            自分に通知しない
                          </b-form-checkbox>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </b-card-body>
              <b-card-footer>
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <span>
                      <b-button variant="primary" @click="saveApprovalProxy">保存</b-button>
                    </span>
                    <span>
                      <b-button v-if="pk" variant="danger" @click="deleteApprovalProxy">削除</b-button>
                      <b-button variant="dark" :to="{ name: 'proxy-list' }">戻る</b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Toast } from '../../components';
import { ajax, common, config, constant } from '../../utils';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      approval_selected: {},
      approval_proxy: {},
      workflow_choice: [],
      approver_choice: [],
    }
  },
  computed: {
    ...mapGetters('account', ['getOrganizationchoices']),
    pk() {
      return this.$route.params.id;
    },
  },
  watch: {
    approval_selected: function(selected) {
      this.approval_proxy.dst_user = selected.value;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize () {
      await this.getWorkflowChoice();
      await this.getApproverChoice();
      if (this.pk) {
        await this.getApprovalProxy();
      }
      this.loading = false;
    },
    getWorkflowChoice() {
      const vm = this;
      return ajax.fetchGet(config.api.task.workflow_list).then(data => {
        vm.workflow_choice = data.results.map(item => ({
          value: item.code,
          text: item.name,
        }));
      }).catch(errors => {
        helper.show_errors(errors);
      });
    },
    getApproverChoice() {
      const vm = this;
      return ajax.fetchGet(config.api.master.approver_choice).then(data => {
        vm.approver_choice = data;
      }).catch(errors => {
        helper.show_errors(errors);
      });
    },
    getApprovalProxy() {
      const vm = this;
      return ajax.fetchGet(common.formatStr(config.api.account.approval_proxy_detail, this.pk)).then(data => {
        vm.approval_proxy = data;
        vm.approval_selected = vm.approver_choice.find(i => i.value === data.dst_user);
      }).catch(errors => {
        helper.show_errors(errors);
      });
    },
    onApprovalChange(value) {  // eslint-disable-line
    },
    saveApprovalProxy() {
      if (!this.approval_proxy.workflow) {
        Toast.error(common.formatStr(constant.ERROR.REQUIRED_FIELD, 'ワークフロー'));
        return;
      }
      if (!this.approval_proxy.dst_user) {
        Toast.error(common.formatStr(constant.ERROR.REQUIRED_FIELD, '承認者'));
        return;
      }
      if (confirm(constant.CONFIRM.SAVE)) {
        const vm = this;
        vm.loading = true;
        let req = null;
        if (vm.pk) {
          req = ajax.fetchPut(common.formatStr(config.api.account.approval_proxy_detail, this.pk), this.approval_proxy);
        } else {
          req = ajax.fetchPost(config.api.account.approval_proxy_list, this.approval_proxy);
        }
        req.then(() => {
          Toast.success(constant.SUCCESS.SAVED);
          vm.$router.push({ name: 'proxy-list' });
        }).catch(errors => {
          helper.show_errors(errors);
        }).finally(() => {
          vm.loading = false;
        });
      }
    },
    deleteApprovalProxy() {
      if (this.pk) {
        if (confirm(constant.CONFIRM.DELETE)) {
          const vm = this;
          vm.loading = true;
          ajax.fetchDelete(common.formatStr(config.api.account.approval_proxy_detail, this.pk)).then(() => {
            Toast.success(constant.SUCCESS.DELETED);
            vm.$router.push({ name: 'proxy-list' });
          }).catch(errors => {
            helper.show_errors(errors);
          }).finally(() => {
            vm.loading = false;
          });
        }
      }
    },
  },
}
</script>